@import "./theme/fonts.scss";

html {
  font-family: Optimist, Arial, Helvetica, sans-serif;
  background: #fff;
}

body {
  margin: 0;
}

h2 {
  font-family: "Optimist SemiBold";
  font-size: clamp(40px, 6vw, 64px);
  line-height: 1.125em;
  margin: 0;
}

.subtitle {
  font-family: "Optimist Light";
  font-size: clamp(20px, 3vw, 32px);
  font-weight: 300;

  margin-top: clamp(16px, 2.4vw, 24px);
  //margin at bottom of description to the CTA button
  margin-bottom: clamp(32px, 4.8vw, 48px);

  line-height: clamp(30px, 1.3em, 54px);

  opacity: 0;
}

* {
  position: relative;
  z-index: 0;
}

.grv-button {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: Optimist, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  padding: 0 16px;
  -webkit-appearance: none;
  background: #0276b1;
  border: 0;
  color: #fff;
}

.grv-button:hover {
  background: #026597;
  color: #fff;
  text-decoration: none;
}

.grv-button:active {
  background: #014e74;
  color: #fff;
  text-decoration: none;
}

// animate fade in and fade out
.fade-enter {
  opacity: 0 !important;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  // these 3 props are needed to not interfere
  // with the page layout until after the old page
  // was removed
  position: fixed;
  top: 0;
  max-height: 100vh;

  opacity: 1 !important;
  transition: opacity 200ms ease-in;
}

.fade-exit,
.fade-enter-done {
  opacity: 1 !important;
}
.fade-exit.fade-exit-active {
  opacity: 0 !important;
  transition: opacity 200ms ease-in;
}
.fade-exit-done {
  opacity: 0 !important;
}

// only animate fade out
.fade-out-enter,
.fade-out-exit {
  opacity: 1 !important;
}

.fade-out-enter.fade-out-enter-active {
  opacity: 0 !important;
}

.fade-out-exit.fade-out-exit-active {
  opacity: 0 !important;
  transition: opacity 250ms ease-in;
}

.fade-out-exit-done {
  display: none;
}
