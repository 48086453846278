@charset "UTF-8";

@font-face {
  font-family: "capitalonegoals";
  src: url("fonts/capitalonegoals.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "capitalonegoals" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "capitalonegoals" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-card:before {
  content: "\65";
}
.icon-experiences:before {
  content: "\66";
}
.icon-gift-cards:before {
  content: "\67";
}
.icon-kids-savings:before {
  content: "\68";
}
.icon-teen-checking:before {
  content: "\69";
}
.icon-learn-and-grow:before {
  content: "\6a";
}
.icon-control-checking:before {
  content: "\6b";
}
.icon-control-early-deposit:before {
  content: "\6c";
}
.icon-control-meet-eno:before {
  content: "\6d";
}
.icon-shopping:before {
  content: "\6e";
}
.icon-shop-rewards:before {
  content: "\70";
}
.icon-tsa-precheck:before {
  content: "\72";
}
.icon-travel-rewards:before {
  content: "\73";
}
