video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoPlayer div,
.videoPlayer {
  height: 100%;
  width: 100%;
}

.videoPlayer:not(.readyToPlay) {
  visibility: hidden;
}
