$breakpoint-mobile: 600px;

.footer_web {
  // make the footer float atop the scrollfixed video
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;

  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 20vh;
  background: white;
  color: #676868;
  font-size: 12px;
  height: 64px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .copyright {
    @media screen and (max-width: $breakpoint-mobile) {
      flex: 0 0 100%;
      text-align: center;
      //order: 2;
    }
  }

  a,
  img {
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }

  a {
    color: #676868;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus,
    &:visited:hover {
      color: #255f82;
    }
    &:visited {
      color: #676868;
    }
    padding: 5px;
    @media screen and (max-width: $breakpoint-mobile) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  a:not(:last-child) {
    border-right: solid 1px gray;
  }

  > div:nth-child(2) {
    flex-grow: 2;

    display: flex;
    justify-content: center;
  }
}

.footer_cafe {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  height: 100vh;
  justify-content: space-around;

  .pincard {
    flex-basis: 30vw;
    margin-left: 20vw;
    padding: 50px;

    .pincard_img {
      width: clamp(280px, 30vw, 800px);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
      border-radius: 25px;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .pincard_text {
    flex-basis: 30vw;
    margin-right: 10vw;
    color: white;
    h4 {
      font-family: "Optimist SemiBold";
      font-size: clamp(32px, 6vh, 6vw);
      line-height: clamp(32px, 6vh, 6vw);
      margin-bottom: 0.3em;
    }

    text-align: left;
    //padding-left: clamp(20px, 5vw, 100px);
    font-size: clamp(18px, 3vh, 3vw);
  }
}
