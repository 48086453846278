.headerBar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  height: clamp(22px, 3.3vw, 27px);
  padding: 16px;

  //dark background at 54% opacity to reach WCAG AA color contrast
  background-color: rgba(0, 0, 0, 0.54);

  .c1logo {
    z-index: 100;
    position: fixed;

    height: clamp(34px, 3.3vw, 39px);
    top: 10px;

    right: clamp(18px, 2vw, 40px);
  }

  .home {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("/assets/menu_home_gravity.svg");
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(1);
    padding: 16px;
    user-select: none;
    width: clamp(26px, 4vw, 32px);
    height: clamp(22px, 3.3vw, 27px);
  }

  //menu icon and menu list
  .menu_container {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
  }

  .menu {
    position: relative;
    cursor: pointer;
    width: clamp(26px, 4vw, 32px);
    height: clamp(22px, 3.3vw, 27px);
    padding: 16px;
    //border-radius: 5px;
    //background-color: rgba(255,255,255,.15);

    &:focus {
      outline: none;
    }

    &:hover,
    &.active {
      //filter: invert(1);
      background-color: rgba(0, 0, 0, 0.55);
    }

    //animation burger menu to close icon
    &.active {
      .menu_line {
        &:nth-child(1) {
          transform: translateY(200%) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-200%) rotate(-45deg);
        }
      }
    }

    .menu_line {
      position: relative;
      width: 100%;
      height: 20%;
      background-color: #ffffff;
      border-radius: 5000px;
      transition: 0.2s;
      transform-origin: center;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 20%;
      }
      &:nth-child(3) {
        top: 40%;
      }
    }
  }

  .list_menu {
    // for the menu to not be functional any longer
    &:not(.fade-enter-done) {
      pointer-events: none;
      position: relative;
    }

    //minimze hidden menu in order to use CSS animation
    opacity: 0;

    position: relative;
    margin: 0;
    list-style-type: none;
    padding-inline-start: 0;
    color: white;

    li {
      margin-top: 2px;
      opacity: 1;
      background: rgba(20, 20, 20, 0.85);
      //border-radius: 5px;

      font-size: clamp(16px, 2vw, 24px);
      font-family: "Optimist Light";
      font-weight: 300;

      //staggered animation of menu items
      transition: opacity 0.375s;
      &:nth-child(1) {
        transition-delay: 0.075s;
      }
      &:nth-child(2) {
        transition-delay: 0.15s;
      }
      &:nth-child(3) {
        transition-delay: 0.225s;
      }
      &:nth-child(4) {
        transition-delay: 0.3s;
      }
      &:nth-child(5) {
        transition-delay: 0.375s;
      }

      &:hover {
        filter: invert(1);
      }
    }

    a {
      color: white;
      text-decoration: none;
      display: block;
      padding: clamp(10px, 2vw, 16px) clamp(15px, 3vw, 24px);

      //highlight current page item and hover effect
      &.current {
        background: rgba(20, 20, 20, 0.55);
        font-family: "Optimist SemiBold";
      }
    }
  }
}
