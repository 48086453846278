@charset "UTF-8";

@import "iconFont.scss";

@font-face {
  font-family: "Optimist";
  font-weight: normal;
  src: url("fonts/Optimist_W_Rg.woff") format("woff");
}

@font-face {
  font-family: "Optimist Semibold";
  font-weight: normal;
  src: url("fonts/Optimist_W_SBd.woff") format("woff");
}

@font-face {
  font-family: "Optimist Bold";
  font-weight: normal;
  src: url("fonts/Optimist_W_Bd.woff") format("woff");
}

@font-face {
  font-family: "Optimist Light";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Optimist_W_Lt.woff") format("woff");
}

@font-face {
  font-family: "Optimist ExtraLight";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Optimist_W_XLt.woff") format("woff");
}
