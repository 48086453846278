$breakpoint-xxl: 1440px;

.pin_spacer {
  padding-bottom: 0 !important;
}

.pinned_slide,
.scrolling_slide {
  overflow: hidden;

  .video_placeholder {
    width: 100%;
    height: 100vh;
    position: relative;
    // isolation: isolate;
    overflow: hidden;

    .background {
      height: 100%;
      width: 100%;
    }

    .text_overlay {
      position: absolute;
      text-align: center;

      transform-origin: 50% 80%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      color: white;
      //mix-blend-mode: screen;
      will-change: text-shadow, transform;
      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);

      //text container for the intro slide
      .text_container {
        background-color: transparent;
        width: clamp(279px, 70vw, 760px);
        margin: 0 auto;
      }
    }
  }
}

.pinned_slide {
  //bring first scrolling slide up a little
  margin-bottom: -10em;

  //Initial position of intro headline
  .text_overlay {
    h1 {
      margin-top: 35vh;
      font-family: "Optimist Bold";
      font-size: clamp(40px, 9vh, 9vw);
      line-height: 1.125em;
      opacity: 0.85;
    }
  }
}

.scrolling_slide {
  height: auto;

  .video_placeholder {
    isolation: isolate;
    height: 60vh;

    .background {
      position: absolute;
      top: 0;
      left: 0;

      z-index: 20;

      mix-blend-mode: screen;
      opacity: 0;
    }

    .intro_overlay {
      width: 100%;
      height: 100%;

      padding-top: 10vh;
      text-align: center;
      transform: scale(1);
      opacity: 0;

      transform-origin: center 40%;

      //padding-top: 10vh;
      font-size: 12vh;
      background-color: white;

      overflow: hidden;
      color: #141414;
    }
  }

  //text container for the 3x content slides
  .text_outer_container {
    width: clamp(279px, 70vw, 928px);
    margin: 0 auto;
    padding: clamp(40px, 8.4vw, 56px) 0;

    background: white;
    // box-shadow: 0 0 50px rgba(0,0,0,.2);
    color: #141414;
    text-align: center;

    h2 {
      margin-top: 0vh;
    }

    h2,
    a,
    .subtitle {
      position: relative;
      opacity: 0;
      //transform: translate(0, 10vh);
      will-change: transform opacity;
    }
  }
}

//animated scroll indicator
.scrollingind {
  position: absolute;
  z-index: 101;

  bottom: 4em;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;

  border-bottom-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: #fff;
  border-left-style: solid;
  border-left-width: 2px;

  transform: rotate(-45deg);
  animation: sdb05 1.5s infinite;

  @keyframes sdb05 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
}
