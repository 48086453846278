.screensaver {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: gray;

  transition: ease opacity 0.6s;
  z-index: 200;

  opacity: 0;
  pointer-events: none;
}

.fade-slow-enter {
  opacity: 0 !important;
  z-index: 1;
}

.fade-slow-enter.fade-slow-enter-active {
  opacity: 1 !important;
  transition: opacity 1000ms ease-in;
}

.fade-slow-exit,
.fade-slow-enter-done {
  opacity: 1 !important;
}
.fade-slow-exit.fade-slow-exit-active {
  opacity: 0 !important;
  transition: opacity 1000ms ease-in;
}
.fade-slow-exit-done {
  opacity: 0 !important;
}
