.pageLoadOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 35vh;

  color: black;
  background: white;
  z-index: 500;

  user-select: none;
  pointer-events: none;

  div {
    text-align: center;
    margin-top: 2vh;

    img.logo {
      width: auto;
      height: 3vh;
      opacity: 0.6;
    }
  }

  .loader {
    text-transform: uppercase;
    font-family: "Optimist Bold";
    color: #fff;
    letter-spacing: 0.2em;
    font-size: 4.5vh;

    span {
      text-shadow: 0 0 15px rgba(204, 208, 212, 0.9),
        0 5px 10px rgba(135, 136, 143, 0.01);

      animation: loading 0.85s ease-in-out infinite alternate;
    }

    span:nth-child(2) {
      animation-delay: 0.15s;
    }

    span:nth-child(3) {
      animation-delay: 0.3s;
    }

    span:nth-child(4) {
      animation-delay: 0.45s;
    }

    span:nth-child(5) {
      animation-delay: 0.6s;
    }

    span:nth-child(6) {
      animation-delay: 0.75s;
    }

    span:nth-child(7) {
      animation-delay: 0.9s;
    }
  }
}

@keyframes loading {
  to {
    text-shadow: 0 0 15px rgba(204, 208, 212, 0.2),
      0 0 3px rgba(135, 136, 143, 0.01);
  }
}
