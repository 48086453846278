.intro {
  position: relative;
  height: 100%;
  width: 100%;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 10;

    pointer-events: none;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  > div {
    position: absolute;
    clip-path: circle(50% at 50% 50%);

    //Left upper corner circle
    &:nth-child(1) > div:not(.videoPlayer) {
      width: 148px;
    }

    //Right lower corner circle
    &:nth-child(2) > div:not(.videoPlayer) {
      width: 190px;
    }

    //Top small circle in background
    &:nth-child(3) > div:not(.videoPlayer) {
      bottom: 24.5%;
      left: 38.3%;
      width: 55.8%;
    }

    //Right small circle
    &:nth-child(4) > div:not(.videoPlayer) {
      width: 190px;
    }

    //Big center circle
    &:nth-child(5) > div:not(.videoPlayer) {
      bottom: 45px;
      width: 280px;
      font-size: 32px;
      line-height: 1.125em;
    }

    > div:not(.videoPlayer) {
      font-family: "Optimist Bold";
      font-size: 22px;
      line-height: 24px;
      text-transform: uppercase;
      opacity: 0.9;
      margin: 0 auto;
      color: #fff;
      text-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
      right: 0;
      left: 0;
      text-align: center;
      position: absolute;
      bottom: 25px;
    }

    > div.videoPlayer {
      width: 100%;
      height: 100%;
    }
  }
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 2048px / 2732px * 100%;
  background: white;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gray_on_hover {
  filter: grayscale(1);

  &:hover {
    filter: none;
  }
}
